import React from 'react';
import { connect } from 'react-redux';
import { Route, State as RouteState } from 'config/routes';
import { BulletinsSectionWrapper, ListingCardsItemWrapper, StyledLink, HomepageCardContainer } from './styled';
import { State } from 'store/state';
import UniversalCard from 'components/cards/listing/UniversalCard';
import { DealType, PoiId, ResolutionPreference, IPoiBulletinIdentifier } from 'utils/entities';
import {
  makeMapStateToPropsFactory,
  PoiGetter,
} from 'components/cards/listing/makeMapStateToPropsFactory';
import { ListingCardsItem } from 'ds/components/ListingCard';
import PowersortCard from './PowersortCard';
import { ListingCardPlaceholder } from 'components/cards/listing/ListingCardPlaceholder';
import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import { ViewPager } from 'components/view-pager';
import { useTheme } from 'hooks/useTheme';
import { SortValue } from 'components/listing-sort/types';
import { ilHomePageBulletinsByIdsSelector } from 'store/state/domainData/selectors';
import { UniversalCardProps } from 'components/cards/listing/UniversalCard/UniversalCard';
import { useLocale } from 'locale';
import { routeByPoiType } from 'utils/marketplaceRoutes';
import { Link } from 'components/link';
import { CardType } from 'components/cards/types';

interface BulletinsSectionProps {
  route: RouteState;
  bulletins: IPoiBulletinIdentifier[];
  total: number;
  powersortImageUrl: string;
  powersortOptions: SortValue[];
  powersortLabel: string;
}

const poiByIdSelector: PoiGetter = (state: State, poiId: PoiId) => ilHomePageBulletinsByIdsSelector(state)[poiId];

const makeMapStateToProps = makeMapStateToPropsFactory(poiByIdSelector);

export const HomePageUniversalCard = (props: UniversalCardProps) => {
  let enhancedProps: UniversalCardProps = {
    ...props,
    hideLogoPreview: true,
    isMinHeight: true,
  };
  if (enhancedProps.cardType === CardType.Bulletin || enhancedProps.cardType === CardType.CommercialBulletin) {
    enhancedProps = {
      ...enhancedProps,
      resolutionPreferences: ResolutionPreference.City,
    };
  }
  return <UniversalCard {...enhancedProps} />;
};


export const ConnectedUniversalCard = connect(makeMapStateToProps)(HomePageUniversalCard);


const placeholders = (new Array(3).fill(null).map((_, idx) => (
  <ListingCardsItemWrapper key={idx}>
    <ListingCardsItem>
      <ListingCardPlaceholder />
    </ListingCardsItem>
  </ListingCardsItemWrapper>
)));

const BulletinsSection: React.FC<BulletinsSectionProps> = ({
  bulletins,
  route,
  total,
  powersortImageUrl,
  powersortOptions,
  powersortLabel,
}) => {
  const { isRTL } = useTheme();
  const { t } = useLocale();
  const designRange = useScreenBreakpoint();
  const isMobile = designRange === 1;

  const cards = bulletins.length ? bulletins.map((bulletin, idx) => (
    <ListingCardsItemWrapper key={bulletin.id}>
      <ListingCardsItem data-auto-bulletin-id={bulletin.id}>
        <Link
          data-auto="listed-bulletin-clickable"
          // todo delete this temporary solution after adding filter type for searchPoi query
          routeName={routeByPoiType(bulletin.type)}
          routeParams={{
            ...route.params,
            id: bulletin.id,
            sort: powersortOptions,
            tracking_event_source: 'homepage_persona',
            tracking_list_index: idx,
            tracking_search_source: 'homepage_persona',
          }}
          hideLinkParams
        >
          <HomepageCardContainer>
            <ConnectedUniversalCard
              onlyOneImageShouldBeDisplayed={isMobile}
              emptyLazyLoadPlaceholder
              id={bulletin.id}
              tagsSortParams={powersortOptions}
            />
          </HomepageCardContainer>
        </Link>
        <Link
          routeName={Route.Search}
          routeParams={{
            term: [ bulletin.addressDetails.cityDocId || bulletin.addressDetails.neighbourhoodDocId ],
            dealType: DealType.Buy,
            sort: powersortOptions,
            tracking_search_source: 'homepage_persona',
          }}
          hideLinkParams
        >
          <StyledLink simple withArrow>
            {t('homePage.bulletins.section.viewCity', { city: bulletin.addressDetails.city })}
          </StyledLink>
        </Link>
      </ListingCardsItem>
    </ListingCardsItemWrapper>
  )) : placeholders;

  return (
    <BulletinsSectionWrapper>
      <PowersortCard
        imageUrl={powersortImageUrl}
        total={total}
        powersortOptions={powersortOptions}
        powerSortLabel={powersortLabel}
      />
      <ShowAt at={1}>
        <ViewPager align="left" isRTL={isRTL}>
          {cards}
        </ViewPager>
      </ShowAt>

      <ShowAt from={2}>
        {cards}
      </ShowAt>
    </BulletinsSectionWrapper>
  );
};


export default BulletinsSection;
