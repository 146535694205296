import styled from '@emotion/styled';
import { Text, H2 } from 'ds/components/typography';

export const RecommendedListingsSectionRoot = styled.div`
  ${({ theme }) => `
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    .slide-btn[dir="next"] {
      left: -${theme.spacing(1.5)};
    }
    .slide-btn[dir="prev"] {
      right: 0;
    }
  `}
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    svg {
      display: block;
      margin-left: ${theme.spacing(1.5)};
    }
  `}
`;

export const SubTitle = styled(Text)`
  color: #827CD0;
`;

export const Title = styled(H2)`
  color: #827CD0;
`;
