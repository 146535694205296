import React from 'react';
import { debounce } from 'lodash';
import { AnalyticsContext } from 'analytics';
import { useSingletonRecommendedListingsCardIntersectionObserver } from 'hooks/useSingletonIntersectionObserver';
import { DealType } from 'utils/entities';
import { UniversalCardProps } from 'components/cards/listing/UniversalCard/UniversalCard';
import { RecommendedListing } from '../types';
import config from 'config';

const DEBOUNCE_TIME = 1000;

export function useRecommendedListingCardEventIntersection<T extends HTMLElement>(
  containerRef: React.MutableRefObject<T>,
  listing: RecommendedListing,
  card: UniversalCardProps
) {
  const { sendEvent } = React.useContext(AnalyticsContext);

  const intersectionHandler = React.useCallback(debounce((e: IntersectionObserverEntry) => {
    try {
      if (e.isIntersecting && listing && card) {
        sendEvent('property_marketplace_expose', 'property', {
          forceProperty: true,
          property: {
            recommendation_model: listing.recommendationVersion,
            property_id: card.id,
            deal_type: card.dealType === DealType.Buy ? 'for_sale' : 'for_rent',
          },
          location: {
            location_id: undefined,
            location_type: 'address',
            street_name: card.streetName,
            street_number: card.streetNumber,
            city: card.city,
            country: config.country,
            neighborhood: card.neighbourhood,
          },
        });
      }
    }
    catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`BI event property_marketplace_expose was not sent on a recommended listing, id=${card && card.id}`);
    }
  }, DEBOUNCE_TIME), [ listing, card ]);

  useSingletonRecommendedListingsCardIntersectionObserver(intersectionHandler, containerRef);
}
