import styled from '@emotion/styled';

export const RecommendedListingCardRoot = styled.div<{ isMobile: boolean; }>`
  ${({ theme, isMobile }) => `
    width: ${isMobile ? '328px' : '359px'};
    min-height: 156px;
    max-height: 180px;
    background-color: ${theme.colors.neutrals.white};
    border-radius: 8px;
    border: 1px solid ${theme.colors.neutrals.grey8};
    > a {
      text-decoration: inherit;
    }
  `}
`;
