import React from 'react';
import { useLocale } from 'locale';
import { H2 } from 'ds/components/typography';
import { Wrapper } from '../projects/styled';
import { RecommendedListingsSection } from 'components/recommended-listings/section';

const RecommendedListings: React.FC = () => {
  const { t } = useLocale();

  return (
    <Wrapper>
      <H2 data-auto="recommended-listings" weight="bold">{t('recommendedListings.section.header.title.home')}</H2>
      <RecommendedListingsSection eventTrackingSource="recommended_listings_carousel_homepage" />
    </Wrapper>
  );
};

export default RecommendedListings;
