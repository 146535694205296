import React, { FC } from 'react';
import { useLocale } from 'locale';
import { IntersectionSpy } from 'components/intersection-spy';
import TitleIcon from 'assets/svg/recommended-listings-section-icon.svg';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { Route, State as RouteState } from 'config/routes';
import * as Styled from './styled';
import { RecommendedListing } from '../types';
import { RecommendedListingsCarousel } from '../carousel/RecommendedListingsCarousel';

export interface RecommendedListingsSectionProps {
  listings: RecommendedListing[];
  isLoading: boolean;
  onIntersection?: (isIntersecting: boolean) => void;
  route: RouteState;
  eventTrackingSource?: string;
}

const intersectionOptions = {
  threshold: [ 0, 1 ],
};

export const RecommendedListingsSection: FC<RecommendedListingsSectionProps> = (props) => {
  const {
    listings,
    isLoading,
    onIntersection,
    route,
    eventTrackingSource = 'recommended_listings_carousel',
  } = props;

  const { t } = useLocale();
  const mediaBreakpoint = useScreenBreakpoint();
  const isMobile = mediaBreakpoint <= 2;
  const shouldObserveInteresection = !!onIntersection;

  const handleIntersection = (entry: IntersectionObserverEntry) => {
    if (onIntersection) {
      onIntersection(entry.isIntersecting);
    }
  };

  if (!(listings && listings.length) || isLoading) return null;

  return (
    <Styled.RecommendedListingsSectionRoot data-auto="recommended-listings-section">
      {route.name === Route.Home ? null : (
        <Styled.Header className="recommended-listings-section-header">
          <TitleIcon width={40} height={40} />
          <Styled.InfoBox>
            <Styled.Title data-auto="recommended-listings-section-header-title" weight="bold">
              {t('recommendedListings.section.header.title')}
            </Styled.Title>
            <Styled.SubTitle data-auto="recommended-listings-section-header-subtitle">
              {t('recommendedListings.section.header.subtitle')}
            </Styled.SubTitle>
          </Styled.InfoBox>
        </Styled.Header>
      )}

      <div className="recommended-listings-carousel">
        <RecommendedListingsCarousel
          listings={listings}
          hideControls={isMobile}
          route={route}
          eventTrackingSource={eventTrackingSource}
        />
        {shouldObserveInteresection && (
          <IntersectionSpy
            onIntersection={handleIntersection}
            intersectionOptions={intersectionOptions}
          />
        )}
      </div>
    </Styled.RecommendedListingsSectionRoot>
  );
};
