import { useScreenBreakpoint } from 'consts/breakpoints';
import { useInterval } from 'hooks/useInterval';
import { useLocale } from 'locale';
import React, { useCallback, useRef, useState } from 'react';
import { HPSlide, AsideCSSDate } from 'store/sagas/apiService/types';
import { getInfiniteNextIndex, getInfinitePrevIndex } from 'utils/gallery';
import * as Styled from './styled';
import ChevronIcon from 'assets/svg/chevron-left.svg';
import { noop, get } from 'lodash';
import { Pager } from 'components/cards/listing/Pager';
import defaultTheme from 'ds/theme/default';
import { colorToAlpha } from 'helpers/insight';
import { createPanResponder, gestureInitialState, GestureState } from 'helpers/pan-responder';
import MortgageOfficesSearchPageEntry from 'screens/HomePage/mortgageOfficesSearchPageEntry/index';
import withEvents from 'analytics/withEvents';
import AnchorMiddleware from 'components/anchor';


const SLIDE_CHANGE_FREQUENCY = 5000;

export interface SliderProps {
  banner: HPSlide[];
  onSubtitleLinkClick?: () => void;
  headerScrollToRef: React.Ref<HTMLHeadingElement>;
  handleMarketingLinkClickEvent?: (desc: string, link: string) => void;
}

export interface SlideTextProps {
  text: string;
  withAttr?: boolean;
  backgroundColor?: string;
  icon?: JSX.Element;
}


const SlideText: React.FC<SlideTextProps> = ({ text, backgroundColor, icon, withAttr }) => {
  return (
    <Styled.SlideTextRoot backgroundColor={backgroundColor}>
      <div data-acc-heading={withAttr ? 'h2' : undefined}>{text}{icon ? icon : null}</div>
    </Styled.SlideTextRoot>
  );
};

interface SlideNavigationProps {
  link: string;
  text: string;
  textBackgroundColor?: string;
  onClick?: () => void;
}

const SlideNavigation: React.FC<SlideNavigationProps> = ({ text, link, textBackgroundColor, onClick }) => {
  if (text && link) {
    return (
      <Styled.Link
        data-auto="navigation-link"
        href={link}
        target="_blank"
        rel="nofollow"
        onClick={onClick}
      >
        <SlideText
          icon={<ChevronIcon width={20} height={35} />}
          backgroundColor={textBackgroundColor}
          text={text}
        />
      </Styled.Link>
    );
  }
  if (text) {
    return (
      <SlideText
        backgroundColor={textBackgroundColor}
        text={text}
      />
    );
  }
  return null;
};

function createAsideImageCssFromData(data: AsideCSSDate): React.CSSProperties {
  if (!data) return {};
  const {
    maxWidth,
    transformX,
    transformY,
  } = data;

  return {
    maxWidth,
    transform: `translate(${transformX ? transformX : 0}px, ${transformY ? `calc(-50% + ${transformY}px)` : '-50%'})`,
  };
}

function extractSliderData(banner: HPSlide[], currentIndex: number) {
  if (!banner) return {};
  const slide = banner[currentIndex];
  const {
    aside,
    title,
    subtitle,
    navigation,
    backgroundFile,
  } = slide;

  const asideImageCss = createAsideImageCssFromData(get(aside, 'imageCssProps'));

  return {
    title,
    subtitle,
    asideImageCss,
    backgroundUrl: get(backgroundFile, 'url'),
    navigationText: get(navigation, 'text'),
    navigationLink: get(navigation, 'link'),
    asideLink: get(aside, 'link'),
    asideUrl: get(aside, [ 'file', 'url' ]),
  };
}


export const SliderBase: React.FC<SliderProps> = (props) => {
  const { banner, headerScrollToRef, children, onSubtitleLinkClick, handleMarketingLinkClickEvent } = props;
  const [ index, setIndex ] = useState(0);
  const [ play, setPlay ] = useState(true);
  const swipeRef = useRef<GestureState>(gestureInitialState);
  const { t } = useLocale();
  const screenBreakpoint = useScreenBreakpoint();
  const isMobile = screenBreakpoint <= 2;
  const slideCount = banner && banner.length;
  const moreThanOneSlide = slideCount > 1;
  const textBackgroundColor = colorToAlpha(defaultTheme.colors.brand.darker, 0.5);

  const handleSlideMove = useCallback((moveTo: 'next' | 'prev') => {
    switch (moveTo) {
      case 'next':
        setIndex((prevIndex) => getInfiniteNextIndex(prevIndex, slideCount));
        break;
      case 'prev':
        setIndex((prevIndex) => getInfinitePrevIndex(prevIndex, slideCount));
        break;
    }
  }, [ getInfiniteNextIndex, getInfinitePrevIndex, setIndex, slideCount ]);

  useInterval(() => {
    if (moreThanOneSlide && play) {
      handleSlideMove('next');
    }
  }, SLIDE_CHANGE_FREQUENCY, [ handleSlideMove, index, moreThanOneSlide, play ]);

  const setGestureState = useCallback((gesture: GestureState) => {
    swipeRef.current = gesture;
  }, []);

  const panHandlersHomePage = useRef(createPanResponder({
    onStart: setGestureState,
    onMove: setGestureState,
    onEnd: (g) => {
      if (swipeRef.current.dx > 35) {
        handleSlideMove('next');
      }
      else if (swipeRef.current.dx < -35) {
        handleSlideMove('prev');
      }
      setGestureState(g);
    },
  }));

  const {
    navigationText,
    navigationLink,
    backgroundUrl,
    asideImageCss,
    asideLink,
    subtitle,
    asideUrl,
    title,
  } = extractSliderData(banner, index);

  const sliderControls = moreThanOneSlide ? (
    <Styled.SliderControls>
      <Styled.PlayControl onClick={() => setPlay((prev) => !prev)}>
        {t('homePage.banner.play.control', { isPlaying: play })}
      </Styled.PlayControl>
      <Styled.PagerWrapper>
        <Pager
          total={banner.length}
          onChangeIndex={setIndex}
          index={index}
        />
      </Styled.PagerWrapper>
    </Styled.SliderControls>
  ) : null;

  return (
    <Styled.Root {...(isMobile ? panHandlersHomePage.current : {})}>
      <Styled.BackgroundImage backgroundImage={backgroundUrl}>
        <Styled.SearchWrapper>
          <Styled.LandingHeading weight="extraBold" ref={headerScrollToRef}>
            <SlideText text={title} />
          </Styled.LandingHeading>
          {children}
          {isMobile ? null : (
            <>
              <Styled.LandingSubHeading>
                {subtitle ? (
                  <Styled.HighlightedText weight="bold">
                    <SlideText withAttr backgroundColor={textBackgroundColor} text={subtitle} />
                  </Styled.HighlightedText>
                ) : null}
                <div>
                  <Styled.HighlightedText weight="bold">
                    <AnchorMiddleware href={navigationLink}>
                      {(href) => (
                        <SlideNavigation
                          onClick={() => handleMarketingLinkClickEvent(subtitle, navigationLink)}
                          link={href}
                          text={navigationText}
                          textBackgroundColor={textBackgroundColor}
                        />
                      )}
                    </AnchorMiddleware>
                  </Styled.HighlightedText>
                </div>
              </Styled.LandingSubHeading>
              {asideUrl ? (
                asideLink ? (
                  <AnchorMiddleware href={asideLink}>
                    {(href) => (
                      <Styled.Link
                        href={href}
                        target="_blank"
                        rel="nofollow"
                        onClick={onSubtitleLinkClick}
                      >
                        <Styled.SearchSectionSideImg style={asideImageCss} data-auto="aside-image" src={asideUrl} />
                      </Styled.Link>
                    )}
                  </AnchorMiddleware>
                ) : <Styled.SearchSectionSideImg style={asideImageCss} data-auto="aside-image" src={asideUrl} />
              ) : null}
            </>
          )}
        </Styled.SearchWrapper>
        {isMobile ? null : sliderControls}
        <MortgageOfficesSearchPageEntry />
      </Styled.BackgroundImage>
      {isMobile ? (
        <Styled.SearchSectionFooter>
          <Styled.SearchSectionFooterRow>
            <Styled.MobileLandingSubHeading weight="bold">
              <SlideText withAttr backgroundColor={colorToAlpha(defaultTheme.colors.brand.main, 0.12)} text={subtitle} />
            </Styled.MobileLandingSubHeading>
            {asideUrl ? (
              asideLink ? (
                <AnchorMiddleware href={asideLink}>
                  {(href) => (
                    <Styled.Link
                      data-auto="aside-image"
                      href={href}
                      target="_blank"
                      rel="nofollow"
                      onClick={onSubtitleLinkClick}
                    >
                      <Styled.SearchSectionFooterImg src={asideUrl} />
                    </Styled.Link>
                  )}
                </AnchorMiddleware>
              ) : <Styled.SearchSectionFooterImg src={asideUrl} />
            ) : null}
            </Styled.SearchSectionFooterRow>
          {isMobile ? sliderControls : null}
          <Styled.MobileWelcomeLargeButton data-auto="madad-button" fullWidth size="large">
            <SlideNavigation
              onClick={() => handleMarketingLinkClickEvent(subtitle, navigationLink)}
              link={navigationLink}
              text={navigationText}
            />
            </Styled.MobileWelcomeLargeButton>
        </Styled.SearchSectionFooter>
      ) : null}
    </Styled.Root>
  );
};

SliderBase.defaultProps = {
  onSubtitleLinkClick: noop,
  handleMarketingLinkClickEvent: noop,
};

export const Slider = withEvents<SliderProps>(sendEvent => ({
  handleMarketingLinkClickEvent(desc: string, link: string) {
    sendEvent('marketing_link_click', 'marketing', {
      event: {
        marketing_link_description: desc,
        marketing_link_url: link,
      },
    });
  },
}))(SliderBase);
