import React  from 'react';
import SearchBlock from './sections/SearchBlock';
import BulletinsSection from './sections/BulletinsSection';
import Footer from 'components/static-footer';
import { useLocale } from 'locale';
import { State } from 'store/state';
import { connect } from 'react-redux';
import { IBulletin, IAutocompleteEntry } from 'utils/entities';
import { SortDirection, SortField } from 'components/listing-sort/types';
import {
  ilHomePageBulletinsSelector,
  ilHomePageBulletinsTotalSelector,
} from 'store/state/domainData/selectors';
import Projects from './sections/projects';
import { Wrapper, FooterWrapper } from './styled';
import Wizard from 'components/wizard';
import {
  setWizardOpen as setWizardOpenAction,
  setSearchStr as setSearchStrAction,
  setDocId as setDocIdAction,
} from 'store/state/homepageWizard/actions';
import { ISetDocIdMeta } from 'store/state/homepageWizard/types';
import { WIZARD_STEPS_IL } from 'store/state/homepageWizard';
import { SetWizardOpenPayload } from 'store/state/homepageWizard/payloads';
import Slider from './sections/slider';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { NavigateToFn, State as RouteState } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { routeSelector } from 'store/state/selectors/router';
import HomepageBanner from './sections/MadadBanner';
import { BlogSectionBg, BlogSectionWrapper } from '../styled';
import BlogSection from 'components/blog-carousel/homepage';
import RecommendedListings from './sections/recommended-listings';
import { hasRecommendedListingsListSelector } from 'store/state/domainData/selectors/recommendedListings';

interface IlHomePageOwnProps {
  totals?: { totalBulletinsSection1: number, totalBulletinsSection2: number, totalBulletinsSection3: number };
  bulletins: IBulletin[];
  setWizardOpen: (state: SetWizardOpenPayload) => void;
  setSearchStr: (value: string) => void;
  setDocId: (input: IAutocompleteEntry, meta: ISetDocIdMeta) => void;
  navigate: NavigateToFn;
  route: RouteState;
  hasRecommendedListings?: boolean;
}

type IlHomePageProps = IlHomePageOwnProps;

const IMAGES_FOLDER = 'homepage/v3';
const HOME_PRICES_IMAGES_RULES = `${IMAGES_FOLDER}/prices.png`;
const HOME_FAMILY_IMAGES_RULES = `${IMAGES_FOLDER}/family.png`;
const HOME_GREEN_IMAGES_RULES = `${IMAGES_FOLDER}/green.png`;

const IlHomePage: React.FC<IlHomePageProps | IlHomePageOwnProps> = (props) => {
  const {
    route,
    totals,
    navigate,
    setDocId,
    bulletins,
    setSearchStr,
    setWizardOpen,
    hasRecommendedListings,
  } = props;

  const { t } = useLocale();
  const screenBreakpoint = useScreenBreakpoint();

  const firstSectionBulletins = bulletins.slice(0, 3);
  const secondSectionBulletins = bulletins.slice(3, 6);
  const thirdSectionBulletins = bulletins.slice(6, 9);
  const fourthSectionBulletins = bulletins.slice(10);

  const handleOpen = () => {
    setWizardOpen({ isOpen: true });
  };

  const handleSearch = (input: IAutocompleteEntry): void => {
    setSearchStr(input.name);
    setDocId(input, { navigateToNextStep: false });
    handleOpen();
  };

  return (
    <>
      <Slider screenBreakpoint={screenBreakpoint} t={t}>
        <SearchBlock
          navigate={navigate}
          handleSearch={handleSearch}
          handleNullSearch={handleOpen}
        />
      </Slider>

      {hasRecommendedListings ? (
        <Wrapper>
          <RecommendedListings />
        </Wrapper>
      ) : null}

      <BulletinsSection
        route={route}
        total={totals && totals.totalBulletinsSection1}
        bulletins={firstSectionBulletins}
        powersortImageUrl={HOME_PRICES_IMAGES_RULES}
        powersortLabel={t('homePage.bulletins.section1.powersortLabel')}
        powersortOptions={[ [ SortField.PriceEstimation, SortDirection.Asc ] ]}
      />

      <Wrapper>
        <Projects
          slides={fourthSectionBulletins}
          route={route}
        />
      </Wrapper>

      <BlogSectionBg>
        <BlogSectionWrapper>
          <BlogSection />
        </BlogSectionWrapper>
      </BlogSectionBg>
      <Wrapper>
        <HomepageBanner />
      </Wrapper>
      <BulletinsSection
        route={route}
        total={totals && totals.totalBulletinsSection2}
        bulletins={secondSectionBulletins}
        powersortImageUrl={HOME_FAMILY_IMAGES_RULES}
        powersortLabel={t('homePage.bulletins.section2.powersortLabel')}
        powersortOptions={[
          [ SortField.FamilyFriendly, SortDirection.Asc ],
          [ SortField.BestSchool, SortDirection.Asc ],
        ]}
      />
      <BulletinsSection
        route={route}
        total={totals && totals.totalBulletinsSection3}
        bulletins={thirdSectionBulletins}
        powersortImageUrl={HOME_GREEN_IMAGES_RULES}
        powersortLabel={t('homePage.bulletins.section3.powersortLabel')}
        powersortOptions={[ [ SortField.QuietStreets, SortDirection.Asc ] ]}
      />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
      <Wizard steps={WIZARD_STEPS_IL} />
    </>
  );
};


const mapStateToProps = (state: State) => ({
  bulletins: ilHomePageBulletinsSelector(state),
  totals: ilHomePageBulletinsTotalSelector(state),
  route: routeSelector(state),
  hasRecommendedListings: hasRecommendedListingsListSelector(state),
});

const mapDispatchToProps = {
  setWizardOpen: setWizardOpenAction,
  setSearchStr: setSearchStrAction,
  setDocId: setDocIdAction,
  navigate: navigateTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(IlHomePage);
