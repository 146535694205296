import React, { FC, useCallback, useMemo, useState } from 'react';
import { Route, State as RouteState } from 'config/routes';
import { BaseCarousel } from 'ds/components/carousels/base-carousel';
import { RecommendedListing } from '../types';
import { RecommendedListingCard } from '../card/RecommendedListingCard';
import { useScreenBreakpoint } from 'consts/breakpoints';
import Carousel from 'ds/components/carousels/carousel';

export interface RecommendedListingsCarouselProps {
  listings: RecommendedListing[];
  hideControls: boolean;
  route: RouteState;
  eventTrackingSource: string;
}

export const RecommendedListingsCarousel: FC<RecommendedListingsCarouselProps> = (props) => {
  const { listings, hideControls, route, eventTrackingSource } = props;
  const [ slideIndex, setSlideIndex ] = useState(0);
  const designRange = useScreenBreakpoint();

  const slides = useMemo(() => {
    return listings.reduce<Array<{ id: string; listing: RecommendedListing; }>>((acc, listing) => {
      if ('bulletin' in listing && listing.bulletin) {
        acc.push({ id: listing.bulletin.id, listing });
      }
      if ('project' in listing && listing.project) {
        acc.push({ id: listing.project.id, listing });
      }
      return acc;
    }, []);
  }, [ listings ]);

  const onCarouselSlideChange = useCallback((idx: number) => {
    setSlideIndex(idx);
  }, [ setSlideIndex ]);

  const mappedSlides = slides.map(slide => (
    <div key={slide.id}>
      <RecommendedListingCard
        listing={slide.listing}
        route={route}
        eventTrackingSource={eventTrackingSource}
      />
    </div>
  ));

  if (route.name === Route.Home) {
    return (
      <Carousel hasArrows={designRange >= 3} isLoading={!slides.length}>
        {mappedSlides}
      </Carousel>
    );
  }

  return (
    <BaseCarousel
      hideShadow
      hideControls={hideControls}
      onSlideIndexChange={onCarouselSlideChange}
      activeCarouselSlide={slideIndex}
      gap={8}
    >
      {mappedSlides}
    </BaseCarousel>
  );
};
