import React from 'react';
import * as Styled from './styled';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { useScreenBreakpoint } from 'consts/breakpoints';

export interface AIDBannerProps {
  bannerClick?: (link: string) => void;
  rootRef?: React.RefObject<HTMLAnchorElement>;
}

export const AIDBanner: React.FC<AIDBannerProps> = ({ bannerClick, rootRef }) => {
  const constructImageURI = useImageUrlBuilder();
  const screenBreakpoint = useScreenBreakpoint();

  const isDesktop = screenBreakpoint >= 2;
  const desktopPath = 'banners/aig/hp_desktop_v5.jpg';
  const mobilePath = 'banners/aig/hp_mobile_v4.jpg';
  const path = isDesktop ? desktopPath : mobilePath;
  const fullImageURL = constructImageURI(path, {});

  const link = 'https://www.yaridirot.co.il/yaridirot-3/';

  return (
    <Styled.Root ref={rootRef} target="_blank" rel="nofollow" href={link}>
      <Styled.BannerImage onClick={() => bannerClick(link)} src={fullImageURL} />
    </Styled.Root>
  );
};
