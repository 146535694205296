import { TrackJS } from 'trackjs';
import { AgentPoc, Bulletin, Project, IThumbnail, MadadSearchResultCategory, Poc, Address, BuildingClass } from 'utils/entities';
import { processImgs } from 'utils/gallery';
import { CardType } from 'components/cards/types';
import { UniversalCardProps } from 'components/cards/listing/UniversalCard/UniversalCard';
import { RecommendedListing } from '../types';

const isAgentPoc = (poc: Poc): poc is AgentPoc => poc.__typename === 'AgentPoc';

function mapBulletinToUniversalCardProps(bulletin: Bulletin): UniversalCardProps {
  try {
    const { poc, addressDetails, virtualTours } = bulletin;
    const parsedFloor = +bulletin.floor;

    return {
      id: bulletin.id,
      cardType: CardType.Bulletin,
      type: 'bulletin',
      dealType: bulletin.dealType,
      price: bulletin.price,
      date: bulletin.firstTimeSeen,
      lastUpdatedDate: bulletin.lastUpdated,
      bedsCount: bulletin.beds,
      floor: !isNaN(parsedFloor) ? parsedFloor : null,
      size: bulletin.area,
      buildingClass: bulletin.buildingClass && bulletin.buildingClass.toLocaleLowerCase() as BuildingClass,
      resolutionPreferences: addressDetails.resolutionPreferences,
      streetNumber: addressDetails.streetNumber,
      streetName: addressDetails.streetName,
      unitNumber: addressDetails.unitNumber || undefined,
      neighbourhood: addressDetails.neighbourhood,
      city: addressDetails.city,
      eventsHistory: bulletin.eventsHistory,
      locationPoint: bulletin.locationPoint,
      altText: bulletin.address,
      hasVirtualTour: virtualTours && virtualTours.hasVirtualTour,
      generalCondition: bulletin.generalCondition,
      area: bulletin.area,
      thumbnails: processImgs(bulletin.images),
      isSearchInWholeCountry: true, // Always display the city name
      isAgent: isAgentPoc(poc),
      logoPath: isAgentPoc(poc) ? (poc.officeContact && poc.officeContact.imageUrl) : undefined,
      officeId: isAgentPoc(poc) ? poc.officeId : undefined,
      isExclusive: isAgentPoc(poc) && poc.exclusivity ? poc.exclusivity.exclusive : false,
      madadSearchResult: isAgentPoc(poc) ? (poc.madadSearchResult as MadadSearchResultCategory) : undefined,
    };
  }
  catch (e) {
    const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
    const message = `Error in mapBulletinToUniversalCardProps: ${errorMessage}`;
    // tslint:disable-next-line: no-console
    console.error(message, e);
    TrackJS.track([ message, e ]);
    return null;
  }
}

function mapProjectToUniversalCardProps(project: Project): UniversalCardProps {
  try {
    const { address, images, developers, locationPoint } = project;
    const mainImage = images.find(image => image.isMain);
    const thumbnails: IThumbnail[] = mainImage
      ? [ { url: mainImage.url, description: mainImage.description } ]
      : images.map(image => ({ url: image.url, description: image.description }));

    const isPromoted = project.promotion && project.promotion.promotionDetails && project.promotion.promotionDetails.some(detail => new Date(detail.endDate) > new Date());

    return {
      id: project.id,
      type: 'project',
      cardType: CardType.Project,
      projectName: project.projectName,
      dealType: project.dealType,
      altText: project.projectName,
      logoPath: project.logoUrl || (developers && developers.length > 0 && developers[0].logoUrl),
      locationPoint: locationPoint ? {
        lng: locationPoint.longitude,
        lat: locationPoint.latitude,
      } : undefined,
      developerId: (developers && developers.length > 0 && developers[0].id) || undefined,
      bedsRange: project.rooms,
      floorRange: project.floors,
      priceRange: project.price,
      city: (address && address.settlement) || (address.document && address.document.ref && address.document.ref.city),
      streetName: (address && address.street) || (address.document && address.document.ref && (address.document.ref as Address).street),
      streetNumber: (address && address.houseNumber && address.houseNumber.toString()) || (address.document && address.document.ref && (address.document.ref as Address).houseNumber),
      neighbourhood: (address.document && address.document.ref && (address.document.ref as Address).neighbourhood),
      date: project.createdAt,
      isPromoted,
      hasDiscount: Boolean(project.discount && project.discount.showDiscount),
      thumbnails,
    };
  }
  catch (e) {
    const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
    const message = `Error in mapProjectToUniversalCardProps: ${errorMessage}`;
    // tslint:disable-next-line: no-console
    console.error(message, e);
    TrackJS.track([ message, e ]);
    return null;
  }
}

export function mapRecommendedListingToUniversalCardProps(listing: RecommendedListing): UniversalCardProps | null {
  if ('bulletin' in listing) {
    return mapBulletinToUniversalCardProps(listing.bulletin);
  }
  if ('project' in listing) {
    return mapProjectToUniversalCardProps(listing.project);
  }

  return null;
}
