import React, { FC, memo, useMemo, useRef } from 'react';
import { Link } from 'react-router5';
import { Route, State as RouteState } from 'config/routes';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { CardType } from 'components/cards/types';
import ListViewUniversalCard from 'components/cards/new-layout/list-view-card';
import { RecommendedListingCardRoot } from './styled';
import { RecommendedListing } from '../types';
import { mapRecommendedListingToUniversalCardProps } from './utils';
import { useRecommendedListingCardEventIntersection } from './useCardIntersectionEvent';
import { HomePageUniversalCard } from 'screens/HomePage/IL/sections/BulletinsSection';
import { ItemWrapper } from 'ds/components/carousels/carousel/styled';

function getRouteName(cardType: CardType): Route | null {
  switch (cardType) {
    case CardType.Bulletin:
      return Route.UnitPage;
    case CardType.Project:
      return Route.ProjectPage;
    default:
      return null;
  }
}

interface RecommendedListingCardProps {
  listing: RecommendedListing;
  route: RouteState;
  eventTrackingSource: string;
}

export const RecommendedListingCard: FC<RecommendedListingCardProps> = memo(props => {
  const { listing, route, eventTrackingSource } = props;
  const mediaBreakpoint = useScreenBreakpoint();
  const isMobile = mediaBreakpoint <= 2;
  const cardRef = useRef(null);

  const universalCardProps = useMemo(() => mapRecommendedListingToUniversalCardProps(listing), [ listing ]);

  useRecommendedListingCardEventIntersection(cardRef, listing, universalCardProps);

  if (!universalCardProps || !universalCardProps.id) return null;

  const routeName = getRouteName(universalCardProps.cardType);
  const routeParams = {
    ...route.params,
    id: universalCardProps.id,
    tracking_event_source: eventTrackingSource,
  };

  if (!routeName || !routeParams.id) return null;

  if (route.name === Route.Home) {
    return (
      <ItemWrapper ref={cardRef}>
        <Link
          data-auto="recommended-listing-clickable"
          routeName={routeName}
          routeParams={routeParams}
          target={isMobile ? '_self' : '_blank'}
        >
          <HomePageUniversalCard
            {...universalCardProps}
            onlyOneImageShouldBeDisplayed={isMobile}
            emptyLazyLoadPlaceholder
          />
        </Link>
      </ItemWrapper>
    );
  }

  return (
    <RecommendedListingCardRoot isMobile={isMobile} ref={cardRef}>
      <Link
        data-auto="recommended-listing-clickable"
        routeName={routeName}
        routeParams={routeParams}
        target={isMobile ? '_self' : '_blank'}
      >
        <ListViewUniversalCard small {...universalCardProps} />
      </Link>
    </RecommendedListingCardRoot>
  );
});
