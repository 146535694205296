import { State } from 'store/state';
import { connect } from 'react-redux';
import withEvents from 'analytics/withEvents';
import { recommendedListingsListSelector, recommendedListingsLoadingSelector } from 'store/state/domainData/selectors/recommendedListings';
import { RecommendedListingsSectionProps, RecommendedListingsSection as UnwrappedRecommendedListingsSection } from './RecommendedListingsSection';
import { routeSelector } from 'store/state/selectors/router';

const mapStateToProps = (state: State) => ({
  listings: recommendedListingsListSelector(state),
  isLoading: recommendedListingsLoadingSelector(state),
  route: routeSelector(state),
});

const UnwrappedRecommendedListingsSectionWithEvents = withEvents<RecommendedListingsSectionProps>(sendEvent => ({
  onIntersection(isIntersecting: boolean) {
    if (isIntersecting) {
      sendEvent('page_scroll_to_section', 'page', { event: { page_section: 'recommended_listings_carousel' } });
    }
  },
}))(UnwrappedRecommendedListingsSection);

export const RecommendedListingsSection = connect(mapStateToProps)(UnwrappedRecommendedListingsSectionWithEvents);
